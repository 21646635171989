import React from "react"
import { Box, Flex } from "rebass";
import { Link } from "gatsby";

import { rhythm } from "../utils/typography";
import isActivePage from "../utils/isActivePage";
import getSlug from "../utils/slugMapping";


const MobileNavigator = ({ dates, places, moods, isOpen, location }) =>
    <Flex css={styles.container} style={{ top: isOpen ? 0 : '' }}
        flexDirection='column'
        alignItems='center'
    >
        <Flex css={styles.menu} style={{marginTop: rhythm(0.5)}} flexDirection='column'>
            <Box>Thời gian</Box>
            {dates.map(date =>
                <Link
                    key={date}
                    style={styles.link}
                    to={`/thoi-gian/${date}`}
                >
                    <Box
                        css={styles.subMenu}
                        style={styles.getSubMenuStyle(location, `/thoi-gian/${date}`)}
                    >
                        {date}
                    </Box>
                </Link>
            )}
        </Flex>

        <Flex css={styles.menu} flexDirection='column'>
            <Box>Tâm Trạng</Box>
            {moods.map(mood => <Link
                key={mood}
                style={styles.link}
                to={`/tam-trang/${getSlug(mood)}`}
            >
                <Box
                    css={styles.subMenu}
                    style={styles.getSubMenuStyle(location, `/tam-trang/${getSlug(mood)}`)}
                >
                    {mood}
                </Box>
            </Link>)}
        </Flex>
        <Flex css={styles.menu} flexDirection='column'>
            <Box>Nơi</Box>
            {places.map(place => <Link
                key={place}
                style={styles.link}
                to={`/noi/${getSlug(place)}`}
            >
                <Box
                    css={styles.subMenu}
                    style={styles.getSubMenuStyle(location, `/noi/${getSlug(place)}`)}
                >
                    {place}
                </Box>
            </Link>)}
        </Flex>
    </Flex>


const styles = {
    getSubMenuStyle: (location, path) => ({
        ...isActivePage(location, path) ?
            { fontWeight: 600 } : {}
    }),
    link: {
        boxShadow: `none`,
        textDecoration: `none`,
        color: `inherit`,
    },
    container: `
        background:#181818;
        color:#FFF;
        position: absolute;
        top: -650px;
        left: 0;
        width: 100%;
        height: 650px;
        padding: 20px;
        transition: top 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
        overflow: hidden;
        box-sizing: border-box;
    `,
    menu: `
        margin-top: ${rhythm(1)};
        margin-bottom: 0;
        width: 200px;
    `,
    subMenu: `
        font-family: 'Merriweather,Georgia,serif';
        font-weight: 300;
        margin-top: ${rhythm(0.5)};
        margin-bottom: 0;
        margin-left: ${rhythm(3 / 4)}
    `,
}

export default MobileNavigator
