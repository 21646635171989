const mapping = {
    'lửng lơ': 'lung-lo',
    'vui': 'vui',
    'buồn': 'buon',
    'Sài Gòn': 'sai-gon',
    'Đà Lạt': 'da-lat',
    'Vũng Tàu': 'vung-tau',
    'Hội An': 'hoi-an'
};

const getSlug = (text) => mapping[text] || text;

module.exports = getSlug;