import React from "react";
import { Link } from "gatsby";
import { SocialIcon } from 'react-social-icons';

import { rhythm } from "../utils/typography";
import isActivePage from "../utils/isActivePage";
import getSlug from "../utils/slugMapping";


const Navigator = ({ dates, places, moods, social, location }) =>
    <div css={`
    display: flex;
    flex-direction: column;
    position: fixed;
`}>
        <h3 style={styles.menu}>Thời gian</h3>
        {dates.map(date => <Link
            key={date}
            style={styles.link}
            to={`/thoi-gian/${date}`}>
            <h4 style={styles.getSubMenuStyle(location, `/thoi-gian/${date}`)}>{date}</h4>
        </Link>)
        }

        <h3 style={styles.menu}>Tâm Trạng</h3>
        {moods.map(mood => <Link
            key={getSlug(mood)}
            style={styles.link}
            to={`/tam-trang/${getSlug(mood)}`}>
            <h4 style={styles.getSubMenuStyle(location, `/tam-trang/${getSlug(mood)}`)}>{mood}</h4>
        </Link>)}
        <h3 style={styles.menu}>Nơi</h3>
        {places.map(place => <Link
            key={getSlug(place)}
            style={styles.link}
            to={`/noi/${getSlug(place)}`}>
            <h4 style={styles.getSubMenuStyle(location, `/noi/${getSlug(place)}`)}>{place}</h4>
        </Link>)}
        <div style={styles.socialContainer}>
            <SocialIcon style={styles.socialIcon} url={`https://facebook.com/${social.facebook}`}></SocialIcon>
            <SocialIcon style={styles.socialIcon} url={`https://instagram.com/${social.instagram}`}></SocialIcon>
        </div>
    </div>


const styles = {
    getSubMenuStyle: (location, path) => ({
        ...styles.subMenu,
        ...isActivePage(location, path) ?
            { fontWeight: 600 } : {}
    }),
    link: {
        boxShadow: `none`,
        textDecoration: `none`,
        color: `inherit`,
    },
    menu: {
        marginTop: rhythm(1),
        marginBottom: 0
    },
    subMenu: {
        fontFamily: "Merriweather,Georgia,serif",
        fontWeight: 300,
        marginTop: rhythm(0.5),
        marginBottom: 0,
        marginLeft: rhythm(3 / 4)
    },
    socialContainer: {
        marginTop: rhythm(1.5),
        marginBottom: rhythm(1)
    },
    socialIcon: {
        height: 40,
        width: 40,
        marginLeft: rhythm(0.5),
        marginRight: rhythm(0.5)
    }
}

export default Navigator
