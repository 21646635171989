import React, { useState } from "react";
import _ from "lodash";
import { Flex, Box } from 'rebass';
import { Link } from "gatsby";
import HamburgerMenu from "react-hamburger-menu";

import MobileNavigator from "../components/MobileNavigator";
import Navigator from "../components/Navigator";

import { rhythm, scale } from "../utils/typography"

import { StaticQuery, graphql } from "gatsby";


const StaticQueryWrapper = props =>
  <StaticQuery
    query = {layoutQuery}
    render = { data => <Layout data={data} {...props}/>}
  />
;

const Layout = ({ location, children, data, displayNav }) => {
  const [isOpen, openMenu] = useState(false);
  const posts = data.allMarkdownRemark.edges;
  const dates = _(posts)
    .map(({ node }) => node.frontmatter.date)
    .uniq()
    .value();
  const places = _(posts)
    .map(({ node }) => node.frontmatter.place)
    .uniq()
    .value();
  const moods = _(posts)
    .map(({ node }) => node.frontmatter.moods)
    .flatten()
    .uniq()
    .value();

  return (
    <div
      css={`
          margin-left: auto;
          margin-right: auto;
          padding: ${rhythm(1)} ${rhythm(1.5)};
          background-color: #8f8c8c1f;
          min-height: 100vh;
        `}
    >
      <nav>
        <MobileNavigator
          location = {location}
          isOpen={isOpen}
          dates={dates}
          places={places}
          moods={moods}
        />
      </nav>
      <Box css={`
        margin-top: ${isOpen ? '650px' : 0};
        transition: margin 500ms cubic-bezier(0.2, 0.04, 0.03, 0.94);
      `}>
        <header css={
          `
            display: flex;
          `
        }>
          <h1
            style={{
              ...scale(1),
              marginBottom: rhythm(1),
              marginTop: 0,
              flex: 1
            }}
          >
            <Link
              style={{
                boxShadow: `none`,
                textDecoration: `none`,
                color: `inherit`,
              }}
              to={`/`}
            >
              <span style={{ ...scale(3 / 5) }}>Chuyện của.</span>
              <span style={{ paddingLeft: rhythm(0.4) }}>Sơn</span>
            </Link>
          </h1>
          <Box
            css={`
              display: none;
              margin-top: ${rhythm(0.5)}
              @media (max-width: 800px) {
                display: block;
              }
            `}
          >
            <HamburgerMenu
              isOpen={isOpen}
              menuClicked={() => isOpen ? openMenu(false) : openMenu(true)}
              width={20}
              height={18}
              strokeWidth={1}
              rotate={0}
              color='black'
              borderRadius={0}
              animationDuration={0.5}
            />
          </Box>
        </header>
        <Flex>
          {
            displayNav ?
            <nav css={`
              flex: 1;
              @media (max-width: 800px) {
                display: none !important
              }
            `}>
              <Navigator 
                location = {location}
                dates={dates}
                places={places}
                moods={moods}
                social={data.site.siteMetadata.social}
              />
          </nav> : null
          }
          <main css={`flex: 4`}>{children}</main>
        </Flex>
      </Box>
    </div>
  )
};


const layoutQuery = graphql`
  query NavigatorQuery {
    site {
      siteMetadata {
        title
        social {
          facebook,
          instagram
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          frontmatter {
            date(formatString: "YYYY")
            moods
            place
          }
        }
      }
  }
}
`

export default StaticQueryWrapper
